import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql, useStaticQuery} from 'gatsby';
import TouchScreen from '../../layouts/TouchScreen/';


const TouchScreenEn = () => {
  const data = useStaticQuery(graphql`
    query touchScreenDatasEn {
      image: file(relativePath: { eq: "touch/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulTypeDeTactile(sort: {fields: createdAt, order: ASC}, filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            functionalities
            gif {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulTactile {
        edges {
          node {
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  return (
    <Layout 
    lang="en-CA" 
    switchLink='/bornes-tactiles'
    slider={true}
    headerImage={data.image.childImageSharp} 
    title="We've developed expertise for touch screen projects of your industry." 
    subTitle="We carry out tailor-made projects or we personalize our applications according to your needs. We also have our own selection of indoors terminal and are able to provide you the best options of outdoors terminal on the market." 
    anchorLink="#touch-projects-types"
    anchorLinkText="Learn more">
      <Seo title="Interactive Digital Signage Kiosks | Digital Signage Stands"
           description="Our interactive digital signage kiosk includes geotracking, queue management, secure environment, interactive features, and games. Touch-Screen digital signage solutions personalized to your needs."
           lang="en-CA"/>
      <TouchScreen lang="en-CA" types={data.allContentfulTypeDeTactile} carouselDatas={data.allContentfulTactile.edges[1].node}/>
    </Layout>
  )
}

export default TouchScreenEn
